import styled from 'styled-components'

const InputField = ({ name, label, formik, inputEl, ...restProps }) => {
  const { handleChange, handleBlur, touched, errors } = formik

  return (
    <InputRoot error={touched[name] && errors[name]}>
      {label && <label htmlFor={name}>{label}</label>}
      {!inputEl && (
        <input
          name={name}
          id={name}
          onChange={handleChange}
          onKeyPress={handleChange}
          onBlur={handleBlur}
          value={formik.values[name]}
          {...restProps}
        />
      )}
      {inputEl}
      {touched[name] && errors[name] && <InputError>{errors[name]}</InputError>}
    </InputRoot>
  )
}

const InputRoot = styled.div`
  position: relative;
  margin-bottom: 1rem;
  label {
    display: block;
    margin: 0 0 10px;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 1.125rem;
    color: #000;
  }
  input,
  textarea,
  select {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.2rem;
    color: #000;
    background: rgb(255 255 255 / 10%);
    transition: 0.3s;
    ${({ error }) =>
      error &&
      `
      border: 1px solid #ff0000;
    `}
    &:focus {
      outline: 0;
      border: 1px solid #e5960d;
      box-shadow: 0 0 5px rgba(29, 66, 135, 0.25);
      ${({ error }) =>
        error &&
        `
        box-shadow: 0 0 5px rgba(255, 0, 0, 0.25);
      `}
    }
  }
`

const InputError = styled.div`
  color: #f00;
  font-size: 12px;
  margin-top: 5px;
`

export default InputField
