import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ScaleLoader } from 'react-spinners'
import { AiOutlineCheckCircle, AiOutlineClockCircle } from 'react-icons/ai'
import { ImCancelCircle } from 'react-icons/im'

// COMPONENTS
import { Card, PageLoader, PaymentStep } from '../../components'
import { PaymentStatus, PayStatusLoader } from '../../styles/Payments.style'

const PayStep3 = ({ txid, status, payData, error }) => {
  const [loading, setloading] = React.useState(true)

  const navigate = useNavigate()

  React.useEffect(() => {
    if (!payData && status.status === 'pending') {
      navigate(`/payment/${txid}`)
    }

    if (status.status !== 'pending') {
      if (setloading) {
        setloading(false)
      }
    }
  }, [status, payData, txid, navigate])

  if (!status.status && !txid) {
    return (
      <center>
        <h1>URL Not Valid!</h1>
      </center>
    )
  }

  if (!txid || !status) {
    return <PageLoader />
  }

  // console.log(status)

  return (
    <>
      <PaymentStep
        step1
        step2
        step3={status.status === 'approved'}
        status={error ? 'Error' : status.status}
      />
      <div className='ppt-row ppt-row-center'>
        <div className='ppt-col-2 ppt-col-sm-1'>
          <Card>
            {!error && loading && (
              <PayStatusLoader>
                <ScaleLoader height={40} color='#00aeef' />
                <p>Waiting to complete payment.</p>
              </PayStatusLoader>
            )}
            {!loading && !error && status.status !== 'pending' && (
              <PaymentStatus status={status.status}>
                {status.status === 'approved' && (
                  <AiOutlineCheckCircle fontSize={75} />
                )}
                {status.status === 'declined' && (
                  <ImCancelCircle fontSize={75} />
                )}
                {status.status === 'canceled' && (
                  <ImCancelCircle fontSize={75} />
                )}
                {status.status === 'expired' && (
                  <AiOutlineClockCircle color='#ffc107' fontSize={75} />
                )}
                <h4>
                  Payment{' '}
                  {status.status === 'approved' ? 'Successful' : status.status}!
                </h4>
                <p>
                  Your payment has been {status.status}. You will receive a
                  confirmation email shortly.
                </p>
              </PaymentStatus>
            )}
            {error && (
              <PaymentStatus status='Error'>
                <ImCancelCircle fontSize={75} />
                <h4>Payment Cannot be processed!</h4>
                <p>
                  Sorry, Your payment Cannot be processed!. You will receive a
                  confirmation email shortly.
                </p>
              </PaymentStatus>
            )}
          </Card>
        </div>
      </div>
    </>
  )
}

export default PayStep3
