import { PaymentStepItem, PaymentStepWrap } from './paymentStep.style'

const PaymentStep = ({ step1, step2, step3, status }) => {
  return (
    <PaymentStepWrap>
      <PaymentStepItem className={step1 ? 'completed' : 'active'}>
        <div className='ppt-content'>
          <span className='ppt_step-icon'>1</span>
          <span className='ppt_step-text'>Payment Initiated</span>
        </div>
      </PaymentStepItem>
      <PaymentStepItem
        className={step1 && step2 ? 'completed' : step1 ? 'active' : ''}
      >
        <div className='ppt_step-content'>
          <span className='ppt_step-icon'>2</span>
          <span className='ppt_step-line'></span>
          <span className='ppt_step-text'>Payment Details</span>
        </div>
      </PaymentStepItem>
      <PaymentStepItem
        className={
          status &&
          (status === 'canceled' ||
            status === 'declined' ||
            status === 'expired' ||
            status === 'Error')
            ? 'failed'
            : step1 && step2 && step3
            ? 'completed'
            : step2
            ? 'active'
            : ''
        }
      >
        <div className='ppt-content'>
          <span className='ppt_step-icon'>3</span>
          <span className='ppt_step-line'></span>
          <span className='ppt_step-text'>
            Payment {status ? status : 'Details'}
          </span>
        </div>
      </PaymentStepItem>
    </PaymentStepWrap>
  )
}

export default PaymentStep
