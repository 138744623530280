import React from 'react'
import { Route, Routes, useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { io } from 'socket.io-client'

// COMPONENTS
import { Page, PageLoader } from '../../components'
import PayStep1 from './PayStep1'
import PayStep2 from './PayStep2'
import PayStep3 from './PayStep3'

const Payments = () => {
  const [socket, setSocket] = React.useState(null)
  const [status, setStatus] = React.useState('')
  const [method, setMethod] = React.useState('')
  const [methodSelection, setMethodSelection] = React.useState(false)
  const [txid, setTxid] = React.useState('')
  const [payWindow, setPayWindow] = React.useState(null)
  const [payData, setPayData] = React.useState(null)
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const params = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (params.txid) {
      setTxid(params.txid)
    }
  }, [params])

  React.useEffect(() => {
    if (!methodSelection && status && status.payby !== '') {
      setMethod(status.payby)
    }

    if (
      !methodSelection &&
      txid &&
      status &&
      status.payby !== '' &&
      method === ''
    ) {
      navigate(`/payment/${txid}/pay`)
    }
  }, [status, txid, methodSelection, method, navigate])

  React.useEffect(() => {
    setSocket(io(process.env.REACT_APP_SERVER_URL))
  }, [])

  React.useEffect(() => {
    if (txid) {
      emitStatus()
    }

    // eslint-disable-next-line
  }, [txid])

  // checkPayStatus for every 5 seconds
  React.useEffect(() => {
    if (!error) {
      if ((txid && !status) || status.status === 'pending') {
        const interval = setInterval(() => {
          emitStatus()
        }, 5000)

        return () => clearInterval(interval)
      }

      if (status.status === 'approved') {
        setLoading(false)
        navigate(`/payment/${txid}/complete`)

        if (status.returnURL !== '') {
          window.location.href = status.returnURL
        }
      }
    }

    // eslint-disable-next-line
  }, [status, txid, error, navigate])

  React.useEffect(() => {
    if (payWindow && status && status.status !== 'pending') {
      payWindow.close()
    }
  }, [payWindow, status])

  const emitStatus = () => {
    socket.emit('status', txid)

    socket.on('status', (data) => {
      if (data.name === 'Error') {
        setError(true)
      } else {
        setStatus(data)
        setError(false)
      }
    })
  }

  const newWindow = (url) => {
    const left = window.screenLeft ? window.screenLeft : window.screenX
    const top = window.screenTop ? window.screenTop : window.screenY
    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
    const windowFeatures = `width=${width},height=${height},top=${top},left=${left}`
    setPayWindow(window.open(url, 'payWindow', windowFeatures))
  }

  const payApiCall = (values) => {
    setLoading(true)
    socket.emit('payment', values)
    socket.on('payment', (data) => {
      setPayData(data)
      if (data.name === 'Error') {
        setError(true)
      } else {
        setError(false)
        const urlRegx = data.message.match(/https:\/\/[^"]+/g)
        const url = urlRegx[0].split(',')[0].slice(0, -1)
        newWindow(url)
        navigate(`/payment/${txid}/complete`)
      }
      setLoading(false)
    })
  }

  if (!status) {
    return (
      <Page>
        <PageLoader />
      </Page>
    )
  }

  return (
    <Page>
      <Routes>
        <Route
          path='/'
          element={
            <PayStep1
              txid={txid}
              status={status}
              method={method}
              setMethod={setMethod}
            />
          }
        />
        <Route
          path='/pay'
          element={
            <PayStep2
              socket={socket}
              txid={txid}
              status={status}
              method={method}
              setMethod={setMethod}
              setMethodSelection={setMethodSelection}
              payApiCall={payApiCall}
              loading={loading}
            />
          }
        />
        <Route
          path='/complete'
          element={
            <PayStep3
              txid={txid}
              status={status}
              payData={payData}
              error={error}
            />
          }
        />
      </Routes>
    </Page>
  )
}

export default Payments
