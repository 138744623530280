import styled from 'styled-components'

export const PaymentStepWrap = styled.div`
  position: relative;
  display: block;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;

  &:after {
    content: '';
    display: table;
    clear: both;
  }
`

export const PaymentStepItem = styled.div`
  padding: 0;
  position: relative;
  display: block;
  float: left;
  width: 33.33%;
  text-align: center;

  .ppt_step-content {
    display: inline-block;
  }

  .ppt_step-icon {
    background: #eceff1;
    color: #8191ab;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    z-index: 2;
  }

  .ppt_step-text {
    font-size: 13px;
    color: #8191ab;
    font-weight: 500;
  }

  .ppt_step-line {
    display: inline-block;
    height: 3px;
    width: 100%;
    background: #cfd8dc;
    float: left;
    position: absolute;
    left: -50%;
    top: 12px;
    z-index: 1;
  }

  &.active {
    .ppt_step-icon {
      background: #00aeef;
      color: #ffffff;
    }

    .ppt_step-text {
      color: #4f5e77;
    }

    .ppt_step-line {
      background: #00aeef;
    }
  }

  &.completed {
    .ppt_step-text {
      color: #4f5e77;
    }

    .ppt_step-icon {
      background: #00aeef;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBkYXRhLW5hbWU9IkxheWVyIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDkuMTIgNyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTkuMTIgMS4wNkw4LjA2IDAgMy4xOCA0Ljg4IDEuMDYgMi43NiAwIDMuODIgMy4xOCA3bDUuOTQtNS45NHoiLz48L3N2Zz4=);
      color: transparent;
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .ppt_step-line {
      background: #00aeef;
    }
  }

  &.failed {
    .ppt_step-text {
      color: #4f5e77;
    }

    .ppt_step-icon {
      background: #f44336;
      background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%221em%22%20height%3D%221em%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M18.36%2019.78L12%2013.41l-6.36%206.37l-1.42-1.42L10.59%2012L4.22%205.64l1.42-1.42L12%2010.59l6.36-6.36l1.41%201.41L13.41%2012l6.36%206.36z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E');
      color: transparent;
      background-size: 14px;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .ppt_step-line {
      background: #f44336;
    }
  }
`
