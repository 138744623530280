import styled from 'styled-components'

export const FooterWrap = styled.footer`
  position: relative;
  background: #fff;
  padding: 10px 0;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  z-index: 9;
`

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    font-size: 1.5rem;
    text-align: center;
    margin: 0;
    letter-spacing: 0.1rem;
  }
`
