import { MethodItem, MethodsWrap } from './methods.style'

const Methods = ({ onClick, active }) => {
  return (
    <MethodsWrap>
      <div className='ppt-row'>
        <div className='ppt-col-6'>
          <MethodItem
            onClick={() => onClick('etransfer')}
            active={active === 'etransfer' ? true : false}
          >
            <img src='/images/etransfer.svg' alt='etransfer' />
          </MethodItem>
        </div>
        <div className='ppt-col-6'>
          <MethodItem
            onClick={() => onClick('interaconline')}
            active={active === 'interaconline' ? true : false}
          >
            <img src='/images/interaconline.svg' alt='interaconline' />
          </MethodItem>
        </div>
        <div className='ppt-col-6'>
          <MethodItem
            onClick={() => onClick('onlinebanking')}
            active={active === 'onlinebanking' ? true : false}
          >
            <div className='inner'>
              <img src='/images/onlinebanking.svg' alt='onlinebanking' />
              <h5>Online Banking</h5>
            </div>
          </MethodItem>
        </div>
        <div className='ppt-col-6'>
          <MethodItem
            onClick={() => onClick('digitalcheque')}
            active={active === 'digitalcheque' ? true : false}
          >
            <div className='inner'>
              <img src='/images/digitalcheque.svg' alt='digitalcheque' />
              <h5>Digital Cheque</h5>
            </div>
          </MethodItem>
        </div>
      </div>
    </MethodsWrap>
  )
}

export default Methods
