import styled from 'styled-components'

export const CardWrap = styled.div`
  padding: 20px;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 20px;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    h1,
    h2 {
      font-size: 1.25rem;
    }
  }
`
