import { InputLabel, InputWrap, SelectField } from './form.style'

const Input = ({ children, label, name, helperText, error, ...restProps }) => {
  return (
    <InputWrap error={error}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <SelectField error={error} name={name} id={name} {...restProps}>
        {children}
      </SelectField>
      {helperText && <span className='ppt-helptext'>{helperText}</span>}
    </InputWrap>
  )
}

export default Input
