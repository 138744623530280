import { Link } from 'react-router-dom'
import { Page } from '../components'

const Home = () => {
  return (
    <Page>
      <center>
        <h1>Welcome to Payper Terminal</h1>
        <Link to='/payment'>Make a Payment</Link>
      </center>
    </Page>
  )
}

export default Home
