import styled from 'styled-components'

export const MethodsWrap = styled.div``

export const MethodItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  height: 60px;
  border: 1px solid ${({ active }) => (active ? '#00aeef' : '#e6e6e6')};
  border-radius: 3px;
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? 'rgb(0 174 239 / 10%)' : '#fff'};
  transition: all 0.3s ease;

  .inner {
    display: flex;
    align-items: center;
    text-align: center;
  }

  img {
    max-width: 100%;
    max-height: 35px;
    margin: 0 auto;
  }

  h5 {
    margin: 0;
    font-size: 0.875rem;
    margin-left: 1px;
  }

  &:hover {
    border-color: #00aeef;
  }
`
