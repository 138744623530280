import React from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS
import { Card, Methods, PaymentStep } from '../../components'
import { ButtonWrap, PayAmount } from '../../styles/Payments.style'

const PayStep1 = ({ txid, status, method, setMethod }) => {
  const navigate = useNavigate()

  const nextHandler = () => {
    if (txid) {
      navigate(`/payment/${txid}/pay`)
    }
  }

  return (
    <>
      <PaymentStep />
      <div className='ppt-row ppt-row-center'>
        <div className='ppt-col-2 ppt-col-sm-1'>
          <Card>
            <h2>How would you like to pay?</h2>
            <Methods onClick={setMethod} active={method} />
            <br />
            <ButtonWrap>
              <PayAmount>
                <h4>Total Amount:</h4>
                <h4>{status.amount}</h4>
              </PayAmount>
              <button disabled={method.length === 0} onClick={nextHandler}>
                {method.length === 0
                  ? 'Please select a payment method'
                  : 'Continue to secure payment'}
              </button>
            </ButtonWrap>
          </Card>
        </div>
      </div>
    </>
  )
}

export default PayStep1
