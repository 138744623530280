import { Link } from 'react-router-dom'
import { HeaderContainer, HeaderWrap } from './header.style'

const Header = () => {
  return (
    <HeaderWrap>
      <HeaderContainer>
        <h1>
          <Link to='/'>
            <img src='/images/payper-logo.svg' alt='payper' />
          </Link>
        </h1>
      </HeaderContainer>
    </HeaderWrap>
  )
}

export default Header
