import React from 'react'
import { useNavigate } from 'react-router'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AiOutlineDoubleLeft } from 'react-icons/ai'
import { ScaleLoader } from 'react-spinners'
import { BsChevronDoubleDown } from 'react-icons/bs'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

// COMPONENTS
import { Card, Input, PaymentStep, Select } from '../../components'
import {
  ButtonWrap,
  PayAmount,
  PayformWrap,
  SelectedMethod,
  ToggleFields,
  ToggleFieldsBtn,
} from '../../styles/Payments.style'

const PayStep2 = ({
  socket,
  txid,
  status,
  method,
  setMethod,
  setMethodSelection,
  payApiCall,
  loading,
}) => {
  const [toggle, setToggle] = React.useState(false)
  const [ip, setIp] = React.useState('')

  const navigate = useNavigate()

  React.useEffect(() => {
    if ((!method || method.length === 0) && txid) {
      navigate(`/payment/${txid}`)
    }

    // eslint-disable-next-line
  }, [method, status, navigate, txid, setMethod])

  React.useEffect(() => {
    getIp()
  }, [])

  // Get Ip Address
  const getIp = async () => {
    const response = await fetch('https://api.ipify.org?format=json')
    const data = await response.json()
    setIp(data.ip)
  }

  const validationSchema = Yup.object().shape(
    {
      fname: Yup.string(),
      lname: Yup.string(),
      email: Yup.string().required().email().label('Email'),
      phone: Yup.string()
        .required()
        .test('phone', 'Invalid phone number', (value) =>
          isValidPhoneNumber(value || '')
        ),
      address: Yup.string().label('Address'),
      zipcode: Yup.string().label('Zip Code'),
      city: Yup.string().label('City'),
      state: Yup.string().label('State'),
      country: Yup.string().label('Country'),
    },
    { enableReinitialize: true }
  )

  const formik = useFormik({
    initialValues: {
      fname: status.first_name || '',
      lname: status.last_name || '',
      email: status.email || '',
      phone: status.phone || '',
      address: status.address || '',
      zipcode: status.zip_code || '',
      city: status.city || '',
      state: status.state || '',
      country: status.country.toLowerCase() || '',
    },
    validationSchema,
    onSubmit: (values) => {
      const paymentData = {
        email: values.email,
        phone: values.phone,
        items: [
          {
            name: 'item1',
            quantity: 1,
            description: 'description',
            SKU: '19281910',
            unit_price: Number(status.amount.slice(1)),
          },
        ],
        first_name: values.fname,
        last_name: values.lname,
        address: values.address,
        city: values.city,
        state: values.state,
        country: values.country.toLowerCase(),
        zip_code: values.zipcode,
        ip_address: ip,
        ntf_url: `https://api.terminal.payper.ca/ntflistener/${txid}/`,
        shipping_cost: 0,
        udfs: [txid],
      }
      payApiCall(paymentData)
    },
  })

  const cancelHandler = () => {
    socket.emit('cancel', txid)

    socket.on('cancel', (data) => {
      if (data.name === 'Error') {
        console.log(data)
      } else {
        if (status.returnURL !== '') {
          window.location.href = status.returnURL
        }
      }
    })
  }

  const backHandler = () => {
    navigate(`/payment/${txid}`)
    setMethodSelection(true)
  }

  return (
    <>
      <PaymentStep step1 />
      <div className='ppt-row ppt-row-center'>
        <div className='ppt-col-2 ppt-col-sm-1'>
          <Card>
            <center>
              <h2>Selected Payment Method:</h2>
            </center>
            <SelectedMethod>
              <div>
                <img src={`/images/${method}.svg`} alt={method} height={90} />
              </div>
              <button onClick={backHandler}>
                <AiOutlineDoubleLeft /> Go Back
              </button>
            </SelectedMethod>
          </Card>
        </div>
        <div className='ppt-col-2 ppt-col-sm-1'>
          <Card>
            <h2>Payment Details</h2>
            <PayformWrap onSubmit={formik.handleSubmit}>
              <Input
                name='email'
                type='email'
                placeholder='Enter Email Address'
                label='Email Address'
                formik={formik}
                value={formik.values.email}
              />
              <Input
                name='phone'
                label='Phone Number'
                formik={formik}
                inputEl={
                  <PhoneInput
                    id='phone'
                    placeholder='Enter phone number'
                    defaultCountry='CA'
                    value={formik.values.phone}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue('phone', e)
                    }}
                  />
                }
              />
              <ToggleFieldsBtn
                className={toggle ? 'active' : ''}
                type='button'
                onClick={() => setToggle(!toggle)}
              >
                Additional Information <BsChevronDoubleDown />
              </ToggleFieldsBtn>
              <ToggleFields className={toggle ? 'active' : ''}>
                <div className='ppt-row'>
                  <div className='ppt-col-2'>
                    <Input
                      name='fname'
                      placeholder='Enter First Name'
                      label='First Name'
                      formik={formik}
                      value={formik.values.fname}
                    />
                  </div>
                  <div className='ppt-col-2'>
                    <Input
                      name='lname'
                      placeholder='Enter Last Name'
                      label='Last Name'
                      formik={formik}
                      value={formik.values.lname}
                    />
                  </div>
                </div>
                <Input
                  name='address'
                  placeholder='Enter Your Address'
                  label='Your Address'
                  formik={formik}
                  value={formik.values.address}
                />
                <Input
                  name='zipcode'
                  placeholder='Enter Zip Code'
                  label='Zip Code'
                  formik={formik}
                  value={formik.values.zipcode}
                />
                <div className='ppt-row'>
                  <div className='ppt-col-2'>
                    <Input
                      name='city'
                      placeholder='Enter Your City'
                      label='Your City'
                      formik={formik}
                      value={formik.values.city}
                    />
                  </div>
                  <div className='ppt-col-2'>
                    <Input
                      name='state'
                      placeholder='Enter Your State'
                      label='Your State'
                      formik={formik}
                      value={formik.values.state}
                    />
                  </div>
                </div>
                <Select
                  name='country'
                  placeholder='Enter Country Code'
                  label='Your Country'
                  value={formik.values.country.toLowerCase()}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                  helperText={formik.touched.country && formik.errors.country}
                >
                  <option value=''>Select Country</option>
                  <option value='us'>United States</option>
                  <option value='ca'>Canada</option>
                </Select>
              </ToggleFields>
              <ButtonWrap>
                <PayAmount>
                  <h4>Total Amount:</h4>
                  <h4>{status.amount}</h4>
                </PayAmount>
                <button
                  type='submit'
                  disabled={method.length === 0 || loading || !formik.isValid}
                >
                  {method.length === 0
                    ? 'Please select a payment method'
                    : `Pay with ${method}`}
                  {loading && (
                    <span className='ppt-loader'>
                      <ScaleLoader height={13} width={2} color='#fff' />
                    </span>
                  )}
                </button>
                <center>
                  <span className='ppt-btn-link' onClick={cancelHandler}>
                    Cancel Payment
                  </span>
                </center>
              </ButtonWrap>
            </PayformWrap>
          </Card>
        </div>
      </div>
    </>
  )
}

export default PayStep2
