import styled from 'styled-components'

export const PayformWrap = styled.form``

export const ButtonWrap = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 12px 30px;
    border: none;
    border-radius: 3px;
    background-color: #00a8ff;
    color: #fff;
    cursor: pointer;
    width: 100%;
    margin-top: 15px;
    text-transform: capitalize;
    transition: all 0.3s ease;

    &:hover {
      background-color: #008ed7;
    }

    img {
      margin-left: 10px;
      max-width: 60px;
      max-height: 30px;
    }

    :disabled {
      background-color: #64d4ff;
      cursor: not-allowed;
    }
  }

  .ppt-loader {
    margin-left: 10px;
  }

  .ppt-btn-link {
    display: inline-block;
    text-decoration: underline;
    margin: 10px 0 0;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
  }
`

export const PayBtn = styled.div`
  text-align: center;

  img {
    max-width: 100%;
  }

  .pp_message_paynow {
    font-size: 1.35rem;
    font-weight: 700;
    margin: 0 0 15px;
  }

  .pp_checkout_btn span {
    display: none;
  }

  .pp_message_microtext {
    display: none;
  }

  .pp_message_subtext {
    font-weight: 500;
    margin-top: 15px;
  }
`

export const ToggleFieldsBtn = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  border: 1px solid #00a8ff;
  border-radius: 3px;
  padding: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  text-transform: capitalize;
  background-color: rgb(0 168 255 / 10%);

  svg {
    margin-left: 5px;
    transition: all 0.3s ease;
  }

  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
`

export const ToggleFields = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;

  &.active {
    max-height: 500px;
  }
`

export const SelectedMethod = styled.div`
  text-align: center;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;

    @media (max-width: 768px) {
      min-height: 50px;
    }
  }

  img {
    max-width: 170px;

    @media (max-width: 768px) {
      max-width: 100px;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 15px;
    text-decoration: none;
    width: fit-content;

    :hover {
      text-decoration: underline;
    }
  }
`

export const PayAmount = styled.div`
  display: flex;
  justify-content: space-between;

  h4 {
    margin: 0;
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0;
  }
`

export const PayStatusLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 150px;

  p {
    margin: 15px 0 0;
    font-size: 0.8rem;
    letter-spacing: 3px;
  }
`

export const PaymentStatus = styled.div`
  text-align: center;

  svg {
    margin: 0;
    color: ${({ status }) => (status === 'approved' ? '#00a8ff' : '#ff0000')};
  }

  h4 {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 10px 0;
  }

  p {
    margin: 0;
    font-size: 1rem;
  }
`
