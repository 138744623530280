import { Route, Routes } from 'react-router'
import { Header } from './components'
import { Home, Payments } from './pages'

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/payment' element={<Payments />} /> */}
        <Route path='/payment/:txid/*' element={<Payments />} />
      </Routes>
    </>
  )
}

export default App
