import { PageContainer, PageWrap } from './page.style'

const Page = ({ children }) => {
  return (
    <PageWrap>
      <PageContainer>{children}</PageContainer>
    </PageWrap>
  )
}

export default Page
