import styled from 'styled-components'

export const PageWrap = styled.main`
  position: relative;
  min-height: calc(100vh - 60px);
  padding: 40px 0;
`

export const PageContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 0 15px;
`
