import { ScaleLoader } from 'react-spinners'
import { PageLoaderWrap } from './loader.style'

const PageLoader = ({ color = '#00aeef' }) => {
  return (
    <PageLoaderWrap>
      <ScaleLoader color={color} />
    </PageLoaderWrap>
  )
}

export default PageLoader
