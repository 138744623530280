import styled from 'styled-components'

export const InputWrap = styled.div`
  margin-bottom: 1rem;

  .ppt-helptext {
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: ${({ error }) => (error ? 'red' : '#ccc')};
  }
`

export const InputLabel = styled.label`
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
`

export const FieldWrap = styled.div`
  position: relative;
`

export const FieldStatus = styled.span`
  display: flex;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.25rem;
  color: ${({ error }) => (error ? 'red' : '#00a8ff')};
`

export const InputField = styled.input`
  border: 1px solid ${({ error }) => (error ? 'red' : '#ccc')};
  border-radius: 0.25rem;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
  outline: none;
  transition: all 0.2s ease-in-out;

  &:focus {
    border-color: #00a8ff;
  }
`
export const SelectField = styled.select`
  border: 1px solid ${({ error }) => (error ? 'red' : '#ccc')};
  border-radius: 0.25rem;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
  outline: none;
  transition: all 0.2s ease-in-out;

  &:focus {
    border-color: #00a8ff;
  }
`
