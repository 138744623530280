import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-track {
    background: #999;
  }
  ::-webkit-scrollbar-thumb {
    background: #777;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #666;
  }

  * {
    box-sizing: border-box;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    background-color: #fafafa;
  }

  .ppt-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 15px;

    &-center {
      justify-content: center;
    }
  }

  .ppt-col-2 {
    width: 100%;
    flex-basis: calc(50% - 15px);
  }

  .ppt-col-3 {
    width: 100%;
    flex-basis: calc(33.33% - 15px);
  }

  .ppt-col-6 {
    width: 100%;
    flex-basis: calc(50% - 15px);
  }

  @media (max-width: 768px) {
    .ppt-col-sm-1 {
      flex-basis: calc(100% - 15px);
    }
  }
`

export default GlobalStyle
